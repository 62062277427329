import ready from '@ryanmorr/ready';

const selector = '[data-swiper="true"]';

(() => {
  window.addEventListener('DOMContentLoaded', () => {
    ready(selector, (element: HTMLElement) => {
      import('./swiper').then((module) => {
        const swiperOptions = element.dataset.swiperOptions;
        const options = swiperOptions ? JSON.parse(swiperOptions) : {};
        const Swiper = module.default;
        new Swiper(element.querySelector('.swiper-container'), {
          ...options,
        });
      });
    });
  });
})();
