import { Iodine } from '@kingshott/iodine';
import { isEmpty } from 'lodash';
import { isElementHidden } from '@/utils/app';

function formItem() {
  return {
    iodine: new Iodine(),
    formEl: null,
    inputEl: null,
    selectEl: null,
    formItem: {},
    errorMessage: '',
    labelVisible: true,
    blurred: true,
    init() {
      this.formEl = (this as any).$refs.formItem.closest('form');
      this.inputEl = (this as any).$refs.formItemInput.querySelector(
        'input, textarea',
      );
      this.selectEl = (this as any).$refs.formItemInput.querySelector('select');
      this.setErrorMessages();
      this.addEventListeners();
    },
    addEventListeners() {
      const { inputEl, selectEl, formEl } = this as any;
      if (inputEl) {
        inputEl.addEventListener('input', () => {
          this.labelVisible = !!!inputEl.value;
          this.updateErrorMessage(inputEl);
        });
        inputEl.addEventListener('focus', () => {
          this.blurred = false;
        });
        inputEl.addEventListener('blur', () => {
          this.blurred = true;
          this.updateErrorMessage(inputEl);
        });
        this.labelVisible = !!!inputEl.value;
      }
      if (selectEl) {
        selectEl.addEventListener('blur', () => {
          this.updateErrorMessage(selectEl);
        });
        selectEl.addEventListener(
          'choice',
          (e: any) => {
            this.updateErrorMessage(selectEl, e.detail.choice.value);
          },
          false,
        );
      }
      if (formEl) {
        formEl.addEventListener('submit', () => {
          if (inputEl) this.updateErrorMessage(inputEl);
          if (selectEl) this.updateErrorMessage(selectEl);
        });
      }
    },
    updateErrorMessage(
      el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
      value: string | null = null,
    ) {
      this.errorMessage = this.getErrorMessage(el, value);
    },
    setErrorMessages() {
      const { iodine } = this;
      const { errorMessages } = (this as any).$refs.formItem.dataset;
      if (!isEmpty(errorMessages)) {
        iodine.setErrorMessages(JSON.parse(errorMessages));
      }
    },
    getErrorMessage(
      el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement,
      value: string | null,
    ) {
      const { iodine } = this;
      if (el) {
        const error: any = iodine.is(
          value !== null ? value : el.value,
          JSON.parse((this as any).$refs.formItem.dataset.rules),
        );
        const isItemShowed = !isElementHidden((this as any).$refs.formItem);
        if (error !== true && isItemShowed === true && this.blurred) {
          return iodine.getErrorMessage(error);
        }
      }
      return '';
    },
  };
}

(window as any).formItem = formItem;
