import { updateURLParameter } from '@/utils/app';
import { xor } from 'lodash';

function selectMultiple({ id, options, value }: any) {
  if (!Array.isArray(value)) {
    value = value.split(',');
  }
  return {
    id,
    value,
    options,
    states: [],
    selectEl: null,
    open: value.length > 0,
    init() {
      this.selectEl = this.$el.querySelector('select');
      this.addEventListeners();
    },
    addEventListeners() {
      (this as any).$watch('value', (value: any) => {
        const event = new CustomEvent('selectMultipleChange', {
          detail: { id: this.id, value: this.value },
        });
        window.dispatchEvent(event);
        const newUrl = updateURLParameter(window.location.href, 'tags', value);
        history.pushState({}, null, newUrl);
        if (value.length > 0 && !this.open) {
          this.open = true;
        }
      });
      window.addEventListener(
        'toggleSelectMultipleValue',
        ({ detail }: any) => {
          if (detail.id === this.id) {
            this.toggleValue(detail.value);
          }
        },
      );
    },
    toggleValue(value: string) {
      (this as any).value = xor(this.value, [value]);
    },
    hasValue(value: never) {
      if (Array.isArray(this.value)) {
        return Array.from(this.value).includes(value);
      }
      return false;
    },
  };
}

(window as any).selectMultiple = selectMultiple;
