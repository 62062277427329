declare const dataLayer: any;

export const initAnalytics = (gaID: string): any => {
  const s = document.createElement('script');
  s.setAttribute('src', `https://www.googletagmanager.com/gtag/js?id=${gaID}`);
  s.async = true;
  document.head.appendChild(s);

  (window as any).dataLayer = (window as any).dataLayer || [];
  (window as any).gtag = function (a: any, b: any) {
    dataLayer.push(arguments);
  };
  (window as any).gtag('js', new Date());
  (window as any).gtag('config', gaID);
};
