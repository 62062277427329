function useCases({ selectId, markets, applications, locale }: any) {
  if (!Array.isArray(markets)) {
    markets = markets.split(',');
  }
  if (!Array.isArray(applications)) {
    applications = applications.split(',');
  }
  return {
    markets,
    applications,
    locale,
    page: 0,
    loadingMore: false,
    scrolledDown: false,
    async init() {
      this.fetchUseCases(this.markets, this.applications, this.page);
      this.addEventListeners();
    },
    addUseCases(useCases: string, replaceContent: boolean = false) {
      (this as any).$refs['useCases'].innerHTML =
        (replaceContent ? '' : (this as any).$refs['useCases'].innerHTML) +
        useCases;
    },
    fetchUseCases(
      markets: string[],
      applications: string[],
      page: number,
      replaceContent: boolean = false,
    ) {
      this.loadingMore = true;
      return new Promise(async (resolve, reject) => {
        const useCases = await (
          await fetch(
            `/${this.locale}/useCases-filter/${page}?markets=${markets.join(
              ',',
            )}&applications=${applications.join(',')}&locale=${this.locale}`,
          )
        ).text();
        if (useCases) {
          this.addUseCases(useCases, replaceContent);
          return resolve(true);
        }
        return reject();
      }).finally(() => {
        this.loadingMore = false;
      });
    },
    addEventListeners() {
      window.addEventListener('selectMultipleBisChange', ({ detail }: any) => {
        if (detail.id === selectId) {
          this.markets = detail.marketValue;
          this.applications = detail.applicationValue;
          this.page = 0;
          this.fetchUseCases(
            detail.marketValue,
            detail.applicationValue,
            this.page,
            true,
          );
        }
      });
      window.addEventListener('scroll', (e: Event) => {
        const { scrollTop, clientHeight } = document.documentElement;
        const useCasesSectionEl = (this as any).$refs['useCases-section'];
        const useCasesSectionPosition =
          useCasesSectionEl.getBoundingClientRect();
        if (
          scrollTop + clientHeight >
            useCasesSectionPosition.top +
              document.documentElement.scrollTop +
              useCasesSectionPosition.height &&
          !this.loadingMore &&
          !this.scrolledDown
        ) {
          this.scrolledDown = true;
          this.page += 1;
          this.fetchUseCases(this.markets, this.applications, this.page).catch(
            () => {
              this.page -= 1;
            },
          );
        }
        if (
          !(
            scrollTop + clientHeight >
            useCasesSectionPosition.top +
              document.documentElement.scrollTop +
              useCasesSectionPosition.height
          )
        ) {
          this.scrolledDown = false;
        }
      });
    },
  };
}

(window as any).useCases = useCases;
