import { getUrlParamByName } from '@/utils/app';

window.addEventListener('load', () => {
  const sectionToScroll = getUrlParamByName('scrollTo');
  const offset = getUrlParamByName('scrollToOffset');
  if (sectionToScroll) {
    (window as any).Scroller.scrollTo(
      `#${sectionToScroll}`,
      offset ? Number(offset) : 0,
    );
  }
});
