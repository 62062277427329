import { getUrlParamByName } from '@/utils/app';
import { setSelectValue } from '@/plugins/setSelectValue';

function contact() {
  return {
    formType: '',
    init() {
      const sectionToScroll = getUrlParamByName('scrollTo');
      const jobOfferId = getUrlParamByName('jobOffer');
      if (jobOfferId) {
        this.formType = 'jobOffer';
        setSelectValue('isJobOffer', 'contactRequest');
        setSelectValue(jobOfferId, 'jobOffer');
      }
      window.addEventListener('load', () => {
        if (sectionToScroll) {
          (window as any).Scroller.scrollTo(`#${sectionToScroll}`, 0);
        }
      });
    },
    switchFormType(e: any) {
      if (e.target.value === 'isJobOffer') this.formType = 'jobOffer';
      else this.formType = '';
    },
  };
}

(window as any).contact = contact;
