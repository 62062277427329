import Cookies from 'js-cookie';
import { initAnalytics } from '@/modules/analytics';

function cookies(gaId: string) {
  return {
    cookiesName: 'aprex-cookies',
    storedCookies: null,
    open: false,
    launchTransition: false,
    init() {
      this.storedCookies = Cookies.get(this.cookiesName);
      this.open = !this.storedCookies;
      if (this.storedCookies === 'true') {
        gaId.split(',').forEach((id) => initAnalytics(id));
      }
      if (this.open) {
        window.addEventListener('load', () => {
          this.launchTransition = true;
        });
      }
    },
    declineCookies() {
      Cookies.set(this.cookiesName, 'false', { expires: 365 });
      this.open = false;
    },
    acceptCookies() {
      Cookies.set(this.cookiesName, 'true', { expires: 365 });
      this.open = false;
      gaId.split(',').forEach((id) => initAnalytics(id));
    },
  };
}

(window as any).cookies = cookies;
