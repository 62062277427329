import { updateURLParameter } from '@/utils/app';
import { xor } from 'lodash';

function selectMultipleBis({
  id,
  marketValue,
  applicationValue,
  options,
}: any) {
  if (!Array.isArray(marketValue)) {
    marketValue = marketValue.split(',');
  }
  if (!Array.isArray(applicationValue)) {
    applicationValue = applicationValue.split(',');
  }
  return {
    id,
    marketValue,
    applicationValue,
    options,
    states: [],
    selectEl: null,
    open: marketValue.length > 0 || applicationValue.length > 0,
    init() {
      this.selectEl = (this as any).$el.querySelector('select');
      this.addEventListeners();
    },
    addEventListeners() {
      (this as any).$watch('marketValue', (value: any) => {
        this.handleValueChange(value, 'markets');
      });
      (this as any).$watch('applicationValue', (value: any) => {
        this.handleValueChange(value, 'applications');
      });
      window.addEventListener(
        'toggleSelectMultipleBisValue',
        ({ detail }: any) => {
          if (detail.id === this.id) {
            this.toggleValue(detail.value, detail.type);
          }
        },
      );
    },
    toggleValue(value: string, type: string) {
      if (type === 'market') {
        (this as any).marketValue = xor(this.marketValue, [value]);
      } else if (type === 'application') {
        (this as any).applicationValue = xor(this.applicationValue, [value]);
      }
    },
    hasValue(value: never, type: string) {
      if (type === 'market' && Array.isArray(this.marketValue)) {
        return Array.from(this.marketValue).includes(value);
      }
      if (type === 'application' && Array.isArray(this.applicationValue)) {
        return Array.from(this.applicationValue).includes(value);
      }
      return false;
    },
    handleValueChange(changedValue: any, param: string) {
      const event = new CustomEvent('selectMultipleBisChange', {
        detail: {
          id: this.id,
          marketValue: this.marketValue,
          applicationValue: this.applicationValue,
        },
      });
      window.dispatchEvent(event);
      const newUrl = updateURLParameter(
        window.location.href,
        param,
        changedValue,
      );
      history.pushState({}, null, newUrl);
      if (changedValue.length > 0 && !this.open) {
        this.open = true;
      }
    },
  };
}

(window as any).selectMultipleBis = selectMultipleBis;
