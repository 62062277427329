import { debounce } from 'lodash';

function tile() {
  return {
    tileWrapperEl: null,
    tileContentAsideEl: null,
    tileContentMainEl: null,
    init() {
      this.tileWrapperEl = (this as any).$refs.tileContentWrapper;
      this.tileContentAsideEl = (this as any).$refs.tileContentAside;
      this.tileContentMainEl = (this as any).$refs.tileContentMain;
      this.setTile();
      this.addEventListeners();
    },
    getWrapperWidth() {
      return (this as any).tileWrapperEl.offsetWidth;
    },
    splitText(el: any) {
      // eslint-disable-next-line no-param-reassign
      el.innerHTML = el.innerText
        .split(/\s/)
        .map((word: string) => (word !== '' ? `<span>${word}</span>` : ''))
        .join(' ');
    },
    getLines(el: any) {
      const lines: any[] = [];
      let line: string[] = [];
      const words = el.getElementsByTagName('span');
      let currentLineWidth = 0;
      for (let i = 0; i < words.length; i += 1) {
        const word = words[i];
        if (word.offsetWidth + currentLineWidth > this.getWrapperWidth()) {
          lines.push(line.join(' '));
          line = [];
          currentLineWidth = 0;
        } else {
          word.innerText += ' ';
          currentLineWidth += word.offsetWidth;
        }
        line.push(word.innerText);
        if (i === words.length - 1) {
          lines.push(line.join(' '));
        }
      }
      return lines;
    },
    replaceContent(el: any) {
      const lines = this.getLines(el);
      // eslint-disable-next-line no-param-reassign
      el.innerHTML = lines
        .map((line: string) => `<span>${line}</span><br />`)
        .join('');
    },
    setLinesPosition(el: any) {
      const lines = el.getElementsByTagName('span');
      for (let i = 0; i < lines.length; i += 1) {
        const line = lines[i];
        line.style.position = 'absolute';
        line.style.top = `${i * line.offsetHeight}px`;
      }
    },
    setMainContentPosition() {
      (this as any).tileContentMainEl.style.top = `${
        (this as any).tileContentAsideEl.offsetHeight
      }px`;
    },
    setWrapperHeight() {
      (this as any).tileWrapperEl.style.height = `${
        (this as any).tileContentAsideEl.offsetHeight +
        (this as any).tileContentMainEl.offsetHeight
      }px`;
    },
    setTile() {
      [this.tileContentAsideEl, this.tileContentMainEl].forEach((el: any) => {
        this.splitText(el);
        this.replaceContent(el);
        this.setLinesPosition(el);
      });
      this.setMainContentPosition();
      this.setWrapperHeight();
    },
    addEventListeners() {
      window.addEventListener('resize', debounce(this.setTile.bind(this), 100));
      (this as any).$el.addEventListener('mouseenter', () => {
        const spanEls = (this as any).$el.querySelectorAll('span');
        spanEls.forEach((el: any) => {
          // eslint-disable-next-line no-param-reassign
          el.style.left = `${this.getWrapperWidth() - el.offsetWidth}px`;
        });
      });
      (this as any).$el.addEventListener('mouseleave', () => {
        const spanEls = (this as any).$el.querySelectorAll('span');
        spanEls.forEach((el: any) => {
          // eslint-disable-next-line no-param-reassign
          el.style.left = '0';
        });
      });
    },
  };
}

(window as any).tile = tile;
