import tippy, { hideAll } from 'tippy.js';
import 'tippy.js/animations/shift-away.css';
import 'tippy.js/animations/shift-toward.css';
import 'tippy.js/animations/scale.css';
import 'tippy.js/animations/perspective.css';
import { defaultsDeep } from 'lodash';

window.addEventListener('DOMContentLoaded', () => {
  window.addEventListener('resize', () => {
    hideAll({ duration: 0 });
  });
});

function popover() {
  return {
    states: [],
    options: {
      trigger: 'click',
      interactive: true,
      arrow: false,
      placement: 'auto',
      appendTo: () => document.body,
      offset: [0, 0],
      animation: 'shift-toward',
      interactiveDebounce: 0,
      maxWidth: 'none',
    },
    initialize(options: any) {
      if (!options.disabled) {
        delete options.disabled;
        tippy(this.$refs['reference'], {
          ...defaultsDeep(options, this.options),
          content: this.$refs['content'],
        });
      }
    },
  };
}

(window as any).popover = popover;
