/* Add polyfills from the polyfills folder (order matters) */
import '@/polyfills/WeakMap';
import '@/polyfills/MutationObserver';
import '@/polyfills/ObjectFit';
import '@/polyfills/CustomProperties.min';

/* Add scrollbar-width variable for accurate calculations with calc */
document.documentElement.style.setProperty(
  '--scrollbar-width',
  window.innerWidth - document.documentElement.clientWidth + 'px',
);

/* Mitt */
import mitt from 'mitt';
(window as any).emitter = mitt();

/* Import Pristine Config (for HRM)  */
import '@config/pristine.config.js';

/* Alpine */
import 'alpinejs/dist/cdn';

/** Unicons: https://iconscout.com/unicons */
import '@iconscout/unicons/css/line.css';

/* Auto Imports */
const srcContext = require.context(
  '@',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
srcContext.keys().forEach(srcContext);

const templatesContext = require.context(
  '@root/templates',
  true,
  /\.(runtime|asset|style)\.(.*?)$/,
);
templatesContext.keys().forEach(templatesContext);

/* Element Queries */
import ElementQueries from 'css-element-queries/src/ElementQueries';
ElementQueries.listen();

/* Import Styles */
import '@/styles/main.scss';
