function newsletterBanner({ successMessages, errorMessages, formErrors }: any) {
  return {
    formVisible: false,
    successMessages: [],
    errorMessages: [],
    init() {
      (this as any).successMessages = [...JSON.parse(successMessages)];
      if (formErrors) {
        (this as any).errorMessages = [
          ...JSON.parse(errorMessages.replace(/(\s")|("\s)/g, ' ')),
          formErrors,
        ];
      } else {
        (this as any).errorMessages = [
          ...JSON.parse(errorMessages.replace(/(\s")|("\s)/g, ' ')),
        ];
      }
      if (this.errorMessages.length > 0) this.formVisible = true;
      this.addEventListeners();
    },
    addEventListeners() {
      window.addEventListener('load', () => {
        if (this.successMessages.length > 0 || this.errorMessages.length > 0) {
          (window as any).Scroller.scrollTo('#newsletter-banner', 0);
        }
      });
    },
  };
}

(window as any).newsletterBanner = newsletterBanner;
