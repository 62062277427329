import ready from '@ryanmorr/ready';
import { v4 as uuid } from 'uuid';
import { debounce } from 'lodash';
import './plain-overlay.min.js';
var style = document.createElement('style');
style.innerHTML = `
  .plainoverlay {
    background-color: transparent!important;
    overflow: visible!important;
  }
  .plainoverlay:hover {
    cursor: default!important;
    background-color: #0e0e2ca3!important;
  }
  .plainoverlay .plainoverlay-body {
    display: inline-block!important;
  }
  .mediaInfo {
    min-width: 100px;
    min-height: 100px;
    pointer-events: none;
  }
  .mediaInfo__width,
  .mediaInfo__height,
  .mediaInfo__ratio {
    background-color: #0e0e2c;
    color: white;
    margin-right: 4px;
    margin-bottom: 4px;
    padding: 2px 4px;
    white-space: nowrap;
    float: left;
  }
`;
// Get the first script tag
const ref = document.querySelector('script');
// Insert our new styles before the first script tag
if (ref) {
  (ref as any).parentNode.insertBefore(style, ref);
}

class MediaInfo {
  id: string = uuid();
  el: any;
  infoEl: HTMLSpanElement;
  overlay: any = null;
  infoNode!: HTMLSpanElement;
  infoWidthNode!: HTMLSpanElement;
  infoHeightNode!: HTMLSpanElement;
  infoRatioNode!: HTMLSpanElement;
  constructor(element: Element) {
    this.el = element;
    this.infoEl = this.createInfoNode();
    this.overlay = this.createOverlay(this.el, this.infoEl);
    this.overlay.show();
    this.update();
    const debouncedUpdate = debounce(() => {
      this.update();
    }, 500);
    window.addEventListener('resize', debouncedUpdate);
  }
  update() {
    const elWidth = this.el.offsetWidth;
    const elHeight = this.el.offsetHeight;
    this.infoWidthNode.innerText = `w: ${Math.round(elWidth)}px`;
    this.infoHeightNode.innerText = `h: ${Math.round(elHeight)}px`;
    this.infoRatioNode.innerText = `r: ${
      Math.round((elWidth / elHeight) * 10) / 10
    }`;
    this.overlay.position();
  }
  createInfoNode(): HTMLSpanElement {
    this.infoNode = document.createElement('span');
    this.infoNode.classList.add('mediaInfo');
    this.infoNode.setAttribute('data-info-id', this.id);
    this.infoWidthNode = document.createElement('span');
    this.infoWidthNode.classList.add('mediaInfo__width');
    this.infoWidthNode.setAttribute('data-info-id', this.id);
    this.infoHeightNode = document.createElement('span');
    this.infoHeightNode.classList.add('mediaInfo__height');
    this.infoHeightNode.setAttribute('data-info-id', this.id);
    this.infoRatioNode = document.createElement('span');
    this.infoRatioNode.classList.add('mediaInfo__ratio');
    this.infoRatioNode.setAttribute('data-info-id', this.id);
    this.infoNode.appendChild(this.infoWidthNode);
    this.infoNode.appendChild(this.infoHeightNode);
    this.infoNode.appendChild(this.infoRatioNode);
    return this.infoNode;
  }
  createOverlay(el: Element, infoEl: Element) {
    return new PlainOverlay(el, {
      face: infoEl,
    });
  }
}

class MediaInfoManager {
  instances: MediaInfo[] = [];
  initiated: boolean = false;
  addMediaInfo(mediaInfo: MediaInfo) {
    this.instances.push(mediaInfo);
  }
  getAllMediaInfo() {
    if (this.initiated) {
      this.instances.forEach((mediaInfo) => {
        const { overlay } = mediaInfo;
        try {
          if (
            overlay.state === PlainOverlay.STATE_HIDDEN ||
            overlay.state === PlainOverlay.STATE_HIDING
          ) {
            overlay.show();
            overlay.position();
          } else {
            overlay.hide();
          }
        } catch (e) {}
      });
    } else {
      this.initiated = true;
      ready('img, [style*="background-image"]', (el: Element) => {
        this.addMediaInfo(new MediaInfo(el));
      });
    }
    return this.instances;
  }
}
const mediaInfoManagetInstance = new MediaInfoManager();

document.addEventListener('keypress', (e) => {
  if (e.key === 'Tab' && e.ctrlKey) {
    mediaInfoManagetInstance.getAllMediaInfo();
  }
});

(window as any).mediaInfoManager = mediaInfoManagetInstance;
