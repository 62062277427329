/*! PlainOverlay v1.4.17 (c) anseki https://anseki.github.io/plain-overlay/ */
var PlainOverlay = (function (e) {
  var t = {};
  function n(o) {
    if (t[o]) return t[o].exports;
    var i = (t[o] = { i: o, l: !1, exports: {} });
    return e[o].call(i.exports, i, i.exports, n), (i.l = !0), i.exports;
  }
  return (
    (n.m = e),
    (n.c = t),
    (n.d = function (e, t, o) {
      n.o(e, t) || Object.defineProperty(e, t, { enumerable: !0, get: o });
    }),
    (n.r = function (e) {
      'undefined' != typeof Symbol &&
        Symbol.toStringTag &&
        Object.defineProperty(e, Symbol.toStringTag, { value: 'Module' }),
        Object.defineProperty(e, '__esModule', { value: !0 });
    }),
    (n.t = function (e, t) {
      if ((1 & t && (e = n(e)), 8 & t)) return e;
      if (4 & t && 'object' == typeof e && e && e.__esModule) return e;
      var o = Object.create(null);
      if (
        (n.r(o),
        Object.defineProperty(o, 'default', { enumerable: !0, value: e }),
        2 & t && 'string' != typeof e)
      )
        for (var i in e)
          n.d(
            o,
            i,
            function (t) {
              return e[t];
            }.bind(null, i),
          );
      return o;
    }),
    (n.n = function (e) {
      var t =
        e && e.__esModule
          ? function () {
              return e.default;
            }
          : function () {
              return e;
            };
      return n.d(t, 'a', t), t;
    }),
    (n.o = function (e, t) {
      return Object.prototype.hasOwnProperty.call(e, t);
    }),
    (n.p = ''),
    n((n.s = 3))
  );
})([
  function (e, t) {
    e.exports =
      '.plainoverlay:not(.plainoverlay-hide) .plainoverlay-builtin-face_01,.plainoverlay{-webkit-tap-highlight-color:rgba(0,0,0,0);transform:translateZ(0);box-shadow:0 0 1px rgba(0,0,0,0)}.plainoverlay{position:absolute;left:0;top:0;overflow:hidden;background-color:rgba(136,136,136,.6);cursor:wait;z-index:9000;transition-property:opacity;transition-duration:200ms;transition-timing-function:linear;opacity:0}.plainoverlay.plainoverlay-show{opacity:1}.plainoverlay.plainoverlay-force{transition-property:none}.plainoverlay.plainoverlay-hide{display:none}.plainoverlay.plainoverlay-doc{position:fixed;left:-200px;top:-200px;overflow:visible;padding:200px;width:100vw;height:100vh}.plainoverlay-body{width:100%;height:100%;display:flex;justify-content:center;align-items:center}.plainoverlay.plainoverlay-doc .plainoverlay-body{width:100vw;height:100vh}.plainoverlay-builtin-face{width:90%;height:90%;max-width:320px;max-height:320px}#plainoverlay-builtin-face-defs{width:0;height:0;position:fixed;left:-100px;top:-100px}#plainoverlay-builtin-face_01 circle,#plainoverlay-builtin-face_01 path{fill:none;stroke-width:40px}#plainoverlay-builtin-face_01 circle{stroke:#fff;opacity:.25}#plainoverlay-builtin-face_01 path{stroke-linecap:round}.plainoverlay:not(.plainoverlay-hide) .plainoverlay-builtin-face_01{-webkit-animation-name:plainoverlay-builtin-face_01-spin;animation-name:plainoverlay-builtin-face_01-spin;-webkit-animation-duration:1s;animation-duration:1s;-webkit-animation-timing-function:linear;animation-timing-function:linear;-webkit-animation-iteration-count:infinite;animation-iteration-count:infinite}@-webkit-keyframes plainoverlay-builtin-face_01-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}@keyframes plainoverlay-builtin-face_01-spin{from{transform:rotate(0deg)}to{transform:rotate(360deg)}}';
  },
  function (e, t) {
    e.exports =
      '<svg id="plainoverlay-builtin-face-defs" xmlns="http://www.w3.org/2000/svg" version="1.1"><defs><linearGradient id="plainoverlay-builtin-face_01-grad" gradientUnits="userSpaceOnUse" x1="160" y1="20" x2="300" y2="160"><stop offset="0" stop-color="#fff" stop-opacity="0"/><stop offset="0.2" stop-color="#fff" stop-opacity="0.1"/><stop offset="1" stop-color="#fff" stop-opacity="1"/></linearGradient><g id="plainoverlay-builtin-face_01"><circle cx="160" cy="160" r="140"/><path d="M160 20a140 140 0 0 1 140 140" stroke="url(\'#plainoverlay-builtin-face_01-grad\')"/></g></defs></svg>';
  },
  function (e, t) {
    e.exports =
      '<svg class="plainoverlay-builtin-face plainoverlay-builtin-face_01" version="1.1" viewBox="0 0 320 320"><use xlink:href="#plainoverlay-builtin-face_01"/></svg>';
  },
  function (e, t, n) {
    'use strict';
    function o(e) {
      return e.substr(0, 1).toUpperCase() + e.substr(1);
    }
    n.r(t);
    var i,
      r,
      a,
      l,
      s = ['webkit', 'moz', 'ms', 'o'],
      c = s.reduce(function (e, t) {
        return e.push(t), e.push(o(t)), e;
      }, []),
      u = s.map(function (e) {
        return '-'.concat(e, '-');
      }),
      d = function () {
        return (l = l || document.createElement('div').style);
      },
      f =
        ((r = new RegExp('^(?:' + s.join('|') + ')(.)', 'i')),
        (a = /[A-Z]/),
        function (e) {
          return 'float' ===
            (e = (e + '')
              .replace(/\s/g, '')
              .replace(/-([\da-z])/gi, function (e, t) {
                return t.toUpperCase();
              })
              .replace(r, function (e, t) {
                return a.test(t) ? t.toLowerCase() : e;
              })).toLowerCase()
            ? 'cssFloat'
            : e;
        }),
      p =
        ((i = new RegExp('^(?:' + u.join('|') + ')', 'i')),
        function (e) {
          return (null != e ? e + '' : '').replace(/\s/g, '').replace(i, '');
        }),
      m = function (e, t) {
        var n = d();
        return (
          (e = e.replace(/[A-Z]/g, function (e) {
            return '-'.concat(e.toLowerCase());
          })),
          n.setProperty(e, t),
          null != n[e] && n.getPropertyValue(e) === t
        );
      },
      h = {},
      y = {};
    function v(e) {
      if ((e = f(e)) && null == h[e]) {
        var t = d();
        if (null != t[e]) h[e] = e;
        else {
          var n = o(e);
          c.some(function (o) {
            var i = o + n;
            return null != t[i] && ((h[e] = i), !0);
          }) || (h[e] = !1);
        }
      }
      return h[e] || void 0;
    }
    var g,
      w = {
        getName: v,
        getValue: function (e, t) {
          var n;
          return (e = v(e))
            ? ((y[e] = y[e] || {}),
              (Array.isArray(t) ? t : [t]).some(function (t) {
                return (
                  (t = p(t)),
                  null != y[e][t]
                    ? !1 !== y[e][t] && ((n = y[e][t]), !0)
                    : m(e, t)
                    ? ((n = y[e][t] = t), !0)
                    : !!u.some(function (o) {
                        var i = o + t;
                        return !!m(e, i) && ((n = y[e][t] = i), !0);
                      }) || ((y[e][t] = !1), !1)
                );
              }),
              'string' == typeof n ? n : void 0)
            : n;
        },
      },
      b = [],
      T =
        window.requestAnimationFrame ||
        window.mozRequestAnimationFrame ||
        window.webkitRequestAnimationFrame ||
        window.msRequestAnimationFrame ||
        function (e) {
          return setTimeout(e, 1e3 / 60);
        },
      E =
        window.cancelAnimationFrame ||
        window.mozCancelAnimationFrame ||
        window.webkitCancelAnimationFrame ||
        window.msCancelAnimationFrame ||
        function (e) {
          return clearTimeout(e);
        },
      O = Date.now();
    function _() {
      var e, t;
      g && (E.call(window, g), (g = null)),
        b.forEach(function (t) {
          var n;
          (n = t.event) && ((t.event = null), t.listener(n), (e = !0));
        }),
        e ? ((O = Date.now()), (t = !0)) : Date.now() - O < 500 && (t = !0),
        t && (g = T.call(window, _));
    }
    function S(e) {
      var t = -1;
      return (
        b.some(function (n, o) {
          return n.listener === e && ((t = o), !0);
        }),
        t
      );
    }
    var B = {
      add: function (e) {
        var t;
        return -1 === S(e)
          ? (b.push((t = { listener: e })),
            function (e) {
              (t.event = e), g || _();
            })
          : null;
      },
      remove: function (e) {
        var t;
        (t = S(e)) > -1 &&
          (b.splice(t, 1), !b.length && g && (E.call(window, g), (g = null)));
      },
    };
    function D(e) {
      return (e + '').trim();
    }
    function x(e, t) {
      t.setAttribute('class', e.join(' '));
    }
    function N(e, t, n) {
      n.filter(function (t) {
        return !(!(t = D(t)) || -1 !== e.indexOf(t)) && (e.push(t), !0);
      }).length && x(e, t);
    }
    function k(e, t, n) {
      n.filter(function (t) {
        var n;
        return (
          !(!(t = D(t)) || -1 === (n = e.indexOf(t))) && (e.splice(n, 1), !0)
        );
      }).length && x(e, t);
    }
    function P(e) {
      return (
        (!P.ignoreNative && e.classList) ||
        ((t = (e.getAttribute('class') || '')
          .trim()
          .split(/\s+/)
          .filter(function (e) {
            return !!e;
          })),
        (n = {
          length: t.length,
          item: function (e) {
            return t[e];
          },
          contains: function (e) {
            return -1 !== t.indexOf(D(e));
          },
          add: function () {
            return (
              N(t, e, Array.prototype.slice.call(arguments)),
              P.methodChain ? n : void 0
            );
          },
          remove: function () {
            return (
              k(t, e, Array.prototype.slice.call(arguments)),
              P.methodChain ? n : void 0
            );
          },
          toggle: function (n, o) {
            return (function (e, t, n, o) {
              var i = e.indexOf((n = D(n)));
              return -1 !== i
                ? !!o || (e.splice(i, 1), x(e, t), !1)
                : !1 !== o && (e.push(n), x(e, t), !0);
            })(t, e, n, o);
          },
          replace: function (o, i) {
            return (
              (function (e, t, n, o) {
                var i;
                (n = D(n)) &&
                  (o = D(o)) &&
                  n !== o &&
                  -1 !== (i = e.indexOf(n)) &&
                  (e.splice(i, 1), -1 === e.indexOf(o) && e.push(o), x(e, t));
              })(t, e, o, i),
              P.methodChain ? n : void 0
            );
          },
        }))
      );
      var t, n;
    }
    P.methodChain = !0;
    var C = P;
    function A(e, t) {
      for (var n = 0; n < t.length; n++) {
        var o = t[n];
        (o.enumerable = o.enumerable || !1),
          (o.configurable = !0),
          'value' in o && (o.writable = !0),
          Object.defineProperty(e, o.key, o);
      }
    }
    var R = ''.concat('timed', 'TransitionRun'),
      L = ''.concat('timed', 'TransitionStart'),
      I = ''.concat('timed', 'TransitionEnd'),
      M = ''.concat('timed', 'TransitionCancel'),
      F =
        '-ms-scroll-limit' in document.documentElement.style &&
        '-ms-ime-align' in document.documentElement.style &&
        !window.navigator.msPointerEnabled,
      j =
        Number.isFinite ||
        function (e) {
          return 'number' == typeof e && window.isFinite(e);
        },
      H = {},
      z = 0;
    function Y(e, t) {
      var n,
        o =
          (Math.min(Math.max(-e.delay, 0), e.duration) +
            ((t !== I && t !== M) || !e.startTime
              ? 0
              : Date.now() - e.startTime)) /
          1e3;
      try {
        (n = new e.window.TransitionEvent(t, {
          propertyName: e.options.property,
          pseudoElement: e.options.pseudoElement,
          elapsedTime: o,
          bubbles: !0,
          cancelable: !1,
        })),
          F && (n.pseudoElement = e.options.pseudoElement);
      } catch (i) {
        (n =
          e.window.document.createEvent('TransitionEvent')).initTransitionEvent(
          t,
          !0,
          !1,
          e.options.property,
          o,
        ),
          (n.pseudoElement = e.options.pseudoElement);
      }
      (n.timedTransition = e.ins), e.element.dispatchEvent(n);
    }
    function K(e) {
      if (2 === e.state) {
        var t = Date.now() - e.startTime;
        e.currentPosition = e.isOn
          ? Math.min(e.currentPosition + t, e.duration)
          : Math.max(e.currentPosition - t, 0);
      }
    }
    function W(e) {
      (e.state = 0),
        (e.runTime = 0),
        (e.startTime = 0),
        (e.currentPosition = e.isOn ? e.duration : 0);
    }
    function U(e) {
      2 === e.state && ((e.state = 0), Y(e, I), W(e));
    }
    function q(e) {
      if (1 === e.state) {
        (e.state = 2),
          (e.startTime = Date.now()),
          (e.isReversing = !e.isOn),
          Y(e, L);
        var t = e.isOn ? e.duration - e.currentPosition : e.currentPosition;
        t > 0
          ? (e.timer = setTimeout(function () {
              U(e);
            }, t))
          : U(e);
      }
    }
    function X(e) {
      clearTimeout(e.timer), 0 !== e.state && ((e.state = 0), Y(e, M));
    }
    function G(e, t, n) {
      (e.isOn && 0 === e.state) ||
        (e.isOn && 0 !== e.state && !t) ||
        (e.options.procToOn &&
          (n.unshift(!!t), e.options.procToOn.apply(e.ins, n)),
        t || (!e.isOn && 1 === e.state) || -e.delay > e.duration
          ? (X(e), (e.isOn = !0), W(e))
          : (K(e),
            X(e),
            (e.state = 1),
            (e.isOn = !0),
            (e.runTime = Date.now()),
            (e.startTime = 0),
            Y(e, R),
            e.delay > 0
              ? (e.timer = setTimeout(function () {
                  q(e);
                }, e.delay))
              : (e.delay < 0 &&
                  (e.currentPosition = Math.min(
                    e.currentPosition - e.delay,
                    e.duration,
                  )),
                q(e))));
    }
    function V(e, t, n) {
      (!e.isOn && 0 === e.state) ||
        (!e.isOn && 0 !== e.state && !t) ||
        (e.options.procToOff &&
          (n.unshift(!!t), e.options.procToOff.apply(e.ins, n)),
        t || (e.isOn && 1 === e.state) || -e.delay > e.duration
          ? (X(e), (e.isOn = !1), W(e))
          : (K(e),
            X(e),
            (e.state = 1),
            (e.isOn = !1),
            (e.runTime = Date.now()),
            (e.startTime = 0),
            Y(e, R),
            e.delay > 0
              ? (e.timer = setTimeout(function () {
                  q(e);
                }, e.delay))
              : (e.delay < 0 &&
                  (e.currentPosition = Math.max(
                    e.currentPosition + e.delay,
                    0,
                  )),
                q(e))));
    }
    function Z(e, t) {
      var n = e.options;
      function o(n) {
        var o =
          'number' == typeof t[n]
            ? (
                e.window.getComputedStyle(e.element, '')[
                  w.getName('transition-'.concat(n))
                ] || ''
              ).split(',')[t[n]]
            : t[n];
        return 'string' == typeof o ? o.trim() : null;
      }
      'string' == typeof t.pseudoElement && (n.pseudoElement = t.pseudoElement);
      var i = o('property');
      'string' == typeof i && 'all' !== i && 'none' !== i && (n.property = i),
        ['duration', 'delay'].forEach(function (t) {
          var i,
            r,
            a = o(t);
          'string' == typeof a &&
            (/^[0.]+$/.test(a)
              ? ((n[t] = '0s'), (e[t] = 0))
              : (i = /^(.+?)(m)?s$/.exec(a)) &&
                j((r = parseFloat(i[1]))) &&
                ('duration' !== t || r >= 0) &&
                ((n[t] = ''.concat(r).concat(i[2] || '', 's')),
                (e[t] = r * (i[2] ? 1 : 1e3))));
        }),
        ['procToOn', 'procToOff'].forEach(function (e) {
          'function' == typeof t[e]
            ? (n[e] = t[e])
            : t.hasOwnProperty(e) && null == t[e] && (n[e] = void 0);
        });
    }
    var $ = (function () {
        function e(t, n, o) {
          !(function (e, t) {
            if (!(e instanceof t))
              throw new TypeError('Cannot call a class as a function');
          })(this, e);
          var i = {
            ins: this,
            options: { pseudoElement: '', property: '' },
            duration: 0,
            delay: 0,
            isOn: !!o,
          };
          if (
            (Object.defineProperty(this, '_id', { value: ++z }),
            (i._id = this._id),
            (H[this._id] = i),
            !t.nodeType || t.nodeType !== Node.ELEMENT_NODE)
          )
            throw new Error('This `element` is not accepted.');
          (i.element = t),
            n || (n = {}),
            (i.window = t.ownerDocument.defaultView || n.window || window),
            n.hasOwnProperty('property') || (n.property = 0),
            n.hasOwnProperty('duration') || (n.duration = 0),
            n.hasOwnProperty('delay') || (n.delay = 0),
            Z(i, n),
            W(i);
        }
        var t, n, o;
        return (
          (t = e),
          (o = [
            {
              key: 'STATE_STOPPED',
              get: function () {
                return 0;
              },
            },
            {
              key: 'STATE_DELAYING',
              get: function () {
                return 1;
              },
            },
            {
              key: 'STATE_PLAYING',
              get: function () {
                return 2;
              },
            },
          ]),
          (n = [
            {
              key: 'remove',
              value: function () {
                var e = H[this._id];
                clearTimeout(e.timer), delete H[this._id];
              },
            },
            {
              key: 'setOptions',
              value: function (e) {
                return e && Z(H[this._id], e), this;
              },
            },
            {
              key: 'on',
              value: function (e, t) {
                return (
                  arguments.length < 2 &&
                    'boolean' != typeof e &&
                    ((t = e), (e = !1)),
                  this.setOptions(t),
                  G(H[this._id], e, Array.prototype.slice.call(arguments, 2)),
                  this
                );
              },
            },
            {
              key: 'off',
              value: function (e, t) {
                return (
                  arguments.length < 2 &&
                    'boolean' != typeof e &&
                    ((t = e), (e = !1)),
                  this.setOptions(t),
                  V(H[this._id], e, Array.prototype.slice.call(arguments, 2)),
                  this
                );
              },
            },
            {
              key: 'state',
              get: function () {
                return H[this._id].state;
              },
            },
            {
              key: 'element',
              get: function () {
                return H[this._id].element;
              },
            },
            {
              key: 'isReversing',
              get: function () {
                return H[this._id].isReversing;
              },
            },
            {
              key: 'pseudoElement',
              get: function () {
                return H[this._id].options.pseudoElement;
              },
              set: function (e) {
                Z(H[this._id], { pseudoElement: e });
              },
            },
            {
              key: 'property',
              get: function () {
                return H[this._id].options.property;
              },
              set: function (e) {
                Z(H[this._id], { property: e });
              },
            },
            {
              key: 'duration',
              get: function () {
                return H[this._id].options.duration;
              },
              set: function (e) {
                Z(H[this._id], { duration: e });
              },
            },
            {
              key: 'delay',
              get: function () {
                return H[this._id].options.delay;
              },
              set: function (e) {
                Z(H[this._id], { delay: e });
              },
            },
            {
              key: 'procToOn',
              get: function () {
                return H[this._id].options.procToOn;
              },
              set: function (e) {
                Z(H[this._id], { procToOn: e });
              },
            },
            {
              key: 'procToOff',
              get: function () {
                return H[this._id].options.procToOff;
              },
              set: function (e) {
                Z(H[this._id], { procToOff: e });
              },
            },
          ]) && A(t.prototype, n),
          o && A(t, o),
          e
        );
      })(),
      J = n(0),
      Q = n.n(J),
      ee = n(1),
      te = n.n(ee),
      ne = n(2),
      oe = n.n(ne);
    function ie(e, t) {
      if (!(e instanceof t))
        throw new TypeError('Cannot call a class as a function');
    }
    function re(e, t) {
      for (var n = 0; n < t.length; n++) {
        var o = t[n];
        (o.enumerable = o.enumerable || !1),
          (o.configurable = !0),
          'value' in o && (o.writable = !0),
          Object.defineProperty(e, o.key, o);
      }
    }
    C.ignoreNative = !0;
    var ae,
      le,
      se,
      ce = 'plainoverlay',
      ue = ''.concat(ce, '-style'),
      de = ''.concat(ce, '-doc'),
      fe = ''.concat(ce, '-show'),
      pe = ''.concat(ce, '-hide'),
      me = ''.concat(ce, '-force'),
      he = ''.concat(ce, '-body'),
      ye = ''.concat(ce, '-builtin-face-defs'),
      ve =
        '-ms-scroll-limit' in document.documentElement.style &&
        '-ms-ime-align' in document.documentElement.style &&
        !window.navigator.msPointerEnabled,
      ge = !ve && !!document.uniqueID,
      we = 'MozAppearance' in document.documentElement.style,
      be = !(ve || we || !window.chrome || !window.CSS),
      Te =
        ((ae = {}.toString),
        (le = {}.hasOwnProperty.toString),
        (se = le.call(Object)),
        function (e) {
          var t, n;
          return (
            e &&
            '[object Object]' === ae.call(e) &&
            (!(t = Object.getPrototypeOf(e)) ||
              ((n = t.hasOwnProperty('constructor') && t.constructor) &&
                'function' == typeof n &&
                le.call(n) === se))
          );
        }),
      Ee =
        Number.isFinite ||
        function (e) {
          return 'number' == typeof e && window.isFinite(e);
        },
      Oe = {},
      _e = 0;
    function Se(e) {
      setTimeout(function () {
        var t = e.parentNode,
          n = e.nextSibling;
        t.insertBefore(t.removeChild(e), n);
      }, 0);
    }
    function Be(e, t, n, o) {
      var i = e.style;
      return (
        (o || Object.keys(t)).forEach(function (e) {
          null != t[e] &&
            (n && null == n[e] && (n[e] = i[e]), (i[e] = t[e]), (t[e] = null));
        }),
        e
      );
    }
    function De(e, t, n) {
      return Be(e, t, null, n);
    }
    function xe(e, t) {
      var n = e.getBoundingClientRect(),
        o = {
          left: n.left,
          top: n.top,
          right: n.right,
          bottom: n.bottom,
          width: n.width,
          height: n.height,
        };
      return (
        t &&
          ((o.left += t.pageXOffset),
          (o.right += t.pageXOffset),
          (o.top += t.pageYOffset),
          (o.bottom += t.pageYOffset)),
        o
      );
    }
    function Ne(e, t, n, o) {
      return t
        ? (null != o && n.scrollTo(o, n.pageYOffset), n.pageXOffset)
        : (null != o && (e.scrollLeft = o), e.scrollLeft);
    }
    function ke(e, t, n, o) {
      return t
        ? (null != o && n.scrollTo(n.pageXOffset, o), n.pageYOffset)
        : (null != o && (e.scrollTop = o), e.scrollTop);
    }
    function Pe(e) {
      var t = e.elmTarget,
        n = t.clientWidth,
        o = t.clientHeight;
      if (ge || ve) {
        var i = e.window.getComputedStyle(e.elmTargetBody, ''),
          r = i.writingMode || i['writing-mode'],
          a = i.direction;
        return 'tb-rl' === r ||
          'bt-rl' === r ||
          'tb-lr' === r ||
          'bt-lr' === r ||
          (ve &&
            (('ltr' === a && ('vertical-rl' === r || 'vertical-lr' === r)) ||
              ('rtl' === a && ('vertical-rl' === r || 'vertical-lr' === r))))
          ? { width: o, height: n }
          : { width: n, height: o };
      }
      return { width: n, height: o };
    }
    function Ce(e, t) {
      function n(t, n, o, i) {
        try {
          Ne(t, n, e.window, o), ke(t, n, e.window, i);
        } catch (e) {}
      }
      return t
        ? e.savedElementsScroll.some(function (e) {
            return (
              e.element === t && (n(e.element, e.isDoc, e.left, e.top), !0)
            );
          })
        : (e.savedElementsScroll.forEach(function (e) {
            n(e.element, e.isDoc, e.left, e.top);
          }),
          !0);
    }
    function Ae(e, t) {
      return (
        !(
          (!e.isDoc ||
            t === t.ownerDocument.body ||
            e.elmOverlay.compareDocumentPosition(t) &
              Node.DOCUMENT_POSITION_CONTAINED_BY) &&
          (e.isDoc ||
            !(
              t === e.elmTargetBody ||
              e.elmTargetBody.compareDocumentPosition(t) &
                Node.DOCUMENT_POSITION_CONTAINED_BY
            ))
        ) && (t.blur ? t.blur() : t.ownerDocument.body.focus(), !0)
      );
    }
    function Re(e) {
      var t = ('getSelection' in window ? e.window : e.document).getSelection();
      if (
        t.rangeCount &&
        (e.isDoc
          ? !(function (e, t) {
              var n = e.ownerDocument.createRange(),
                o = t.rangeCount;
              n.selectNode(e);
              for (var i = 0; i < o; i++) {
                var r = t.getRangeAt(i);
                if (
                  r.compareBoundaryPoints(Range.START_TO_START, n) < 0 ||
                  r.compareBoundaryPoints(Range.END_TO_END, n) > 0
                )
                  return !1;
              }
              return !0;
            })(e.elmOverlayBody, t)
          : !t.containsNode || (be && t.isCollapsed)
          ? (function (e, t, n) {
              var o = t.ownerDocument.createRange(),
                i = e.rangeCount;
              o.selectNodeContents(t);
              for (var r = 0; r < i; r++) {
                var a = e.getRangeAt(r);
                if (
                  !a.toString().length &&
                  e.toString().length &&
                  1 === i &&
                  (a.setStart(e.anchorNode, e.anchorOffset),
                  a.setEnd(e.focusNode, e.focusOffset),
                  a.toString() !== e.toString() &&
                    (a.setStart(e.focusNode, e.focusOffset),
                    a.setEnd(e.anchorNode, e.anchorOffset),
                    a.toString() !== e.toString()))
                )
                  throw new Error(
                    "Edge bug (Issue #7321753); Couldn't recover",
                  );
                if (
                  n
                    ? a.compareBoundaryPoints(Range.START_TO_END, o) >= 0 &&
                      a.compareBoundaryPoints(Range.END_TO_START, o) <= 0
                    : a.compareBoundaryPoints(Range.START_TO_START, o) < 0 &&
                      a.compareBoundaryPoints(Range.END_TO_END, o) > 0
                )
                  return !0;
              }
              return !1;
            })(t, e.elmTargetBody, !0)
          : t.containsNode(e.elmTargetBody, !0))
      ) {
        try {
          t.removeAllRanges();
        } catch (e) {}
        if ((e.document.body.focus(), t.rangeCount > 0))
          try {
            t.removeAllRanges();
          } catch (e) {}
        return !0;
      }
      return !1;
    }
    function Le(e) {
      var t = e.elmTarget,
        n = e.elmTargetBody,
        o = n.getBoundingClientRect(),
        i = Pe(e),
        r = -i.width,
        a = -i.height;
      if (
        (Be(t, { overflow: 'hidden' }, e.savedStyleTarget),
        (r += (i = Pe(e)).width),
        (a += i.height),
        r || a)
      ) {
        var l,
          s,
          c = e.window.getComputedStyle(n, '');
        if (ge || ve) {
          var u = c.writingMode || c['writing-mode'],
            d = c.direction;
          r &&
            (l = (function (e, t) {
              var n =
                'rl-tb' === e ||
                'tb-rl' === e ||
                'bt-rl' === e ||
                'rl-bt' === e;
              return (
                (ge && n) ||
                (ve &&
                  (n ||
                    ('rtl' === t &&
                      ('horizontal-tb' === e || 'vertical-rl' === e)) ||
                    ('ltr' === t && 'vertical-rl' === e)))
              );
            })(u, d)
              ? 'marginLeft'
              : 'marginRight'),
            a &&
              (s = (function (e, t) {
                var n =
                  'bt-rl' === e ||
                  'bt-lr' === e ||
                  'lr-bt' === e ||
                  'rl-bt' === e;
                return (
                  (ge && n) ||
                  (ve &&
                    (n ||
                      ('rtl' === t &&
                        ('vertical-lr' === e || 'vertical-rl' === e))))
                );
              })(u, d)
                ? 'marginTop'
                : 'marginBottom');
        } else r && (l = 'marginRight'), a && (s = 'marginBottom');
        var f = {};
        return (
          r && (f[l] = ''.concat(parseFloat(c[l]) + r, 'px')),
          a && (f[s] = ''.concat(parseFloat(c[s]) + a, 'px')),
          Be(n, f, e.savedStyleTargetBody),
          (function (e, t, n) {
            var o = e.elmTargetBody,
              i = o.getBoundingClientRect();
            if (
              !(Math.abs(i.width - t) < 0.5 && Math.abs(i.height - n) < 0.5)
            ) {
              var r = e.window.getComputedStyle(o, ''),
                a = r.boxSizing,
                l =
                  'border-box' === a
                    ? []
                    : 'padding-box' === a
                    ? ['border']
                    : ['border', 'padding'],
                s = {
                  border: {
                    width: ['borderLeftWidth', 'borderRightWidth'],
                    height: ['borderTopWidth', 'borderBottomWidth'],
                  },
                  padding: {
                    width: ['paddingLeft', 'paddingRight'],
                    height: ['paddingTop', 'paddingBottom'],
                  },
                },
                c = ['width', 'height'].reduce(
                  function (e, t) {
                    return (
                      l.forEach(function (n) {
                        s[n][t].forEach(function (n) {
                          e[t] -= parseFloat(r[n]);
                        });
                      }),
                      e
                    );
                  },
                  { width: t, height: n },
                );
              Be(
                o,
                {
                  width: c.width > 0 ? ''.concat(c.width, 'px') : 0,
                  height: c.height > 0 ? ''.concat(c.height, 'px') : 0,
                },
                e.savedStyleTargetBody,
              );
              var u = {};
              (i = o.getBoundingClientRect()),
                Math.abs(i.width - t) >= 0.5 &&
                  (u.width = ''.concat(c.width - (i.width - t), 'px')),
                i.height !== n &&
                  (u.height = ''.concat(c.height - (i.height - n), 'px')),
                Be(o, u, e.savedStyleTargetBody);
            }
          })(e, o.width, o.height),
          Ce(e, t),
          !0
        );
      }
      return De(t, e.savedStyleTarget, ['overflow']), !1;
    }
    function Ie(e, t) {
      var n = e.elmTargetBody,
        o = e.window.getComputedStyle(n, ''),
        i = e.elmOverlay,
        r = e.window.getComputedStyle(i, ''),
        a = xe(i, e.window),
        l = ['Top', 'Right', 'Bottom', 'Left'].reduce(function (e, t) {
          return (
            (e[t.toLowerCase()] = parseFloat(o['border'.concat(t, 'Width')])), e
          );
        }, {}),
        s = a.left - parseFloat(r.left),
        c = a.top - parseFloat(r.top),
        u = {
          left: ''.concat(t.left - s + l.left, 'px'),
          top: ''.concat(t.top - c + l.top, 'px'),
          width: ''.concat(t.width - l.left - l.right, 'px'),
          height: ''.concat(t.height - l.top - l.bottom, 'px'),
        },
        d = /^([\d.]+)(px|%)$/;
      [
        { prop: 'TopLeft', hBorder: 'left', vBorder: 'top' },
        { prop: 'TopRight', hBorder: 'right', vBorder: 'top' },
        { prop: 'BottomRight', hBorder: 'right', vBorder: 'bottom' },
        { prop: 'BottomLeft', hBorder: 'left', vBorder: 'bottom' },
      ].forEach(function (e) {
        var n = w.getName('border'.concat(e.prop, 'Radius')),
          i = o[n].split(' '),
          r = i[0],
          a = i[1] || i[0],
          s = d.exec(r);
        (r = s ? ('px' === s[2] ? +s[1] : (s[1] * t.width) / 100) : 0),
          (a = (s = d.exec(a))
            ? 'px' === s[2]
              ? +s[1]
              : (s[1] * t.height) / 100
            : 0),
          (r -= l[e.hBorder]),
          (a -= l[e.vBorder]),
          r > 0 && a > 0 && (u[n] = ''.concat(r, 'px ').concat(a, 'px'));
      }),
        Be(i, u),
        (e.targetBodyBBox = t);
    }
    function Me(e) {
      var t = e.elmTargetBody,
        n = e.elmOverlay,
        o = [e.elmTarget];
      return (
        e.isDoc
          ? (o.push(t),
            Array.prototype.slice.call(t.childNodes).forEach(function (e) {
              e.nodeType !== Node.ELEMENT_NODE ||
                e === n ||
                C(e).contains('plainoverlay') ||
                e.id === ye ||
                (o.push(e),
                Array.prototype.push.apply(o, e.querySelectorAll('*')));
            }))
          : Array.prototype.push.apply(o, t.querySelectorAll('*')),
        o
      );
    }
    function Fe(e) {
      if (((e.filterElements = null), !1 !== e.options.blur)) {
        var t = w.getName('filter'),
          n = w.getValue('filter', 'blur('.concat(e.options.blur, 'px)'));
        if (n) {
          var o = e.isDoc
            ? Array.prototype.slice
                .call(e.elmTargetBody.childNodes)
                .filter(function (t) {
                  return (
                    t.nodeType === Node.ELEMENT_NODE &&
                    t !== e.elmOverlay &&
                    !C(t).contains('plainoverlay') &&
                    t.id !== ye
                  );
                })
                .map(function (e) {
                  return { element: e, savedStyle: {} };
                })
            : [{ element: e.elmTargetBody, savedStyle: {} }];
          o.forEach(function (e) {
            var o = {};
            (o[t] = n), Be(e.element, o, e.savedStyle);
          }),
            (e.filterElements = o);
        }
      }
      (e.state = 2), e.options.onShow && e.options.onShow.call(e.ins);
    }
    function je(e) {
      if (
        (C(e.elmOverlay).add(pe),
        De(e.elmTarget, e.savedStyleTarget),
        De(e.elmTargetBody, e.savedStyleTargetBody),
        (e.savedStyleTarget = {}),
        (e.savedStyleTargetBody = {}),
        (function (e) {
          e.savedElementsAccKeys.forEach(function (e) {
            try {
              !1 === e.tabIndex
                ? e.element.removeAttribute('tabindex')
                : null != e.tabIndex && (e.element.tabIndex = e.tabIndex);
            } catch (e) {}
            try {
              e.accessKey && (e.element.accessKey = e.accessKey);
            } catch (e) {}
          });
        })(e),
        (e.savedElementsAccKeys = []),
        e.isDoc && e.activeElement)
      ) {
        var t = e.state;
        (e.state = 0),
          e.elmTargetBody.removeEventListener('focus', e.focusListener, !0),
          e.activeElement.focus(),
          (e.state = t);
      }
      (e.activeElement = null),
        e.timerRestoreAndFinish &&
          (clearTimeout(e.timerRestoreAndFinish),
          (e.timerRestoreAndFinish = null)),
        (e.timerRestoreAndFinish = setTimeout(function () {
          (e.timerRestoreAndFinish = null),
            (e.state = 0),
            e.elmTargetBody.addEventListener('focus', e.focusListener, !0),
            Ce(e),
            (e.savedElementsScroll = null),
            e.options.onHide && e.options.onHide.call(e.ins);
        }, 0));
    }
    function He(e, t) {
      if (
        !(
          2 === e.state ||
          (1 === e.state && !t) ||
          (1 !== e.state &&
            e.options.onBeforeShow &&
            !1 === e.options.onBeforeShow.call(e.ins))
        )
      ) {
        if (0 === e.state) {
          var n = e.elmOverlay,
            o = C(n);
          e.document.body.appendChild(n);
          var i = Me(e);
          if ((o.remove(pe), !e.isDoc)) {
            var r = e.elmTargetBody;
            'inline' === e.window.getComputedStyle(r, '').display &&
              Be(r, { display: 'inline-block' }, e.savedStyleTargetBody),
              Ie(e, xe(r, e.window));
          }
          (e.savedElementsScroll =
            ((a = i),
            (l = e.isDoc),
            (s = []),
            a.forEach(function (t, n) {
              var o = l && 0 === n;
              (function (t, n) {
                var o = e.window.getComputedStyle(t, ''),
                  i = t.nodeName.toLowerCase();
                return (
                  'scroll' === o.overflow ||
                  'auto' === o.overflow ||
                  'scroll' === o.overflowX ||
                  'auto' === o.overflowX ||
                  'scroll' === o.overflowY ||
                  'auto' === o.overflowY ||
                  (n &&
                    ('visible' === o.overflow ||
                      'visible' === o.overflowX ||
                      'visible' === o.overflowY)) ||
                  (!n && ('textarea' === i || 'select' === i))
                );
              })(t, o) &&
                s.push({
                  element: t,
                  isDoc: o,
                  left: Ne(t, o, e.window),
                  top: ke(t, o, e.window),
                });
            }),
            s)),
            (e.disabledDocBars = !1),
            e.isDoc &&
              e.savedElementsScroll.length &&
              e.savedElementsScroll[0].isDoc &&
              (e.disabledDocBars = Le(e)),
            (e.savedElementsAccKeys = (function (e, t) {
              var n = [];
              return (
                e.forEach(function (e, o) {
                  if (!t || 0 !== o) {
                    var i = {},
                      r = e.tabIndex;
                    -1 !== r &&
                      ((i.element = e),
                      (i.tabIndex = !!e.hasAttribute('tabindex') && r),
                      (e.tabIndex = -1));
                    var a = e.accessKey;
                    a &&
                      ((i.element = e), (i.accessKey = a), (e.accessKey = '')),
                      i.element && n.push(i);
                  }
                }),
                n
              );
            })(i, e.isDoc)),
            (e.activeElement = e.document.activeElement),
            e.activeElement && Ae(e, e.activeElement),
            Re(e),
            n.offsetWidth,
            e.options.onPosition && e.options.onPosition.call(e.ins);
        }
        var a, l, s;
        e.transition.on(t), (e.state = 1), t && Fe(e);
      }
    }
    function ze(e, t) {
      var n = e.options;
      if (
        t.hasOwnProperty('face') &&
        (null == t.face ? void 0 : t.face) !== n.face
      ) {
        for (var o = e.elmOverlayBody; o.firstChild; )
          o.removeChild(o.firstChild);
        if (!1 === t.face) n.face = !1;
        else if (t.face && t.face.nodeType === Node.ELEMENT_NODE)
          (n.face = t.face), o.appendChild(t.face);
        else if (null == t.face) {
          var i = e.document;
          if (!i.getElementById(ye)) {
            var r = new e.window.DOMParser().parseFromString(
              te.a,
              'image/svg+xml',
            );
            i.body.appendChild(r.documentElement);
          }
          (n.face = void 0), (o.innerHTML = oe.a);
        }
      }
      Ee(t.duration) &&
        t.duration !== n.duration &&
        ((n.duration = t.duration),
        (e.elmOverlay.style[w.getName('transitionDuration')] =
          200 === t.duration ? '' : ''.concat(t.duration, 'ms')),
        (e.transition.duration = ''.concat(t.duration, 'ms'))),
        (Ee(t.blur) || !1 === t.blur) && (n.blur = t.blur),
        Te(t.style) && Be(e.elmOverlay, t.style),
        [
          'onShow',
          'onHide',
          'onBeforeShow',
          'onBeforeHide',
          'onPosition',
        ].forEach(function (e) {
          'function' == typeof t[e]
            ? (n[e] = t[e])
            : t.hasOwnProperty(e) && null == t[e] && (n[e] = void 0);
        });
    }
    function Ye(e, t, n, o) {
      var i, r;
      if (t) {
        if (-1 === Me(e).indexOf(t)) return r;
        i = 'html' === t.nodeName.toLowerCase();
      } else (t = e.elmTarget), (i = e.isDoc);
      var a,
        l =
          null != o &&
          e.savedElementsScroll &&
          (e.savedElementsScroll.find
            ? e.savedElementsScroll.find(function (e) {
                return e.element === t;
              })
            : (e.savedElementsScroll.some(function (e) {
                return e.element === t && ((a = e), !0);
              }),
              a));
      return (
        (r = (n ? Ne : ke)(t, i, e.window, o)),
        l && (l[n ? 'left' : 'top'] = r),
        r
      );
    }
    var Ke = (function () {
      function e(t, n) {
        function o(e) {
          var t;
          if (e)
            if (e.nodeType) {
              if (e.nodeType === Node.DOCUMENT_NODE) t = e.documentElement;
              else if (e.nodeType === Node.ELEMENT_NODE) {
                var n = e.nodeName.toLowerCase();
                t =
                  'body' === n
                    ? e.ownerDocument.documentElement
                    : 'iframe' === n || 'frame' === n
                    ? e.contentDocument.documentElement
                    : e;
              }
              if (!t) throw new Error('This element is not accepted.');
            } else e === e.window && (t = e.document.documentElement);
          else t = document.documentElement;
          return t;
        }
        ie(this, e);
        var i = {
          ins: this,
          options: { face: !1, duration: 200, blur: !1 },
          state: 0,
          savedStyleTarget: {},
          savedStyleTargetBody: {},
          blockingDisabled: !1,
        };
        if (
          (Object.defineProperty(this, '_id', { value: ++_e }),
          (i._id = this._id),
          (Oe[this._id] = i),
          1 === arguments.length)
        ) {
          if (!(i.elmTarget = o(t))) {
            if (!Te(t)) throw new Error('Invalid argument.');
            (i.elmTarget = document.documentElement), (n = t);
          }
        } else if (!(i.elmTarget = o(t)))
          throw new Error('This target is not accepted.');
        if (n) {
          if (!Te(n)) throw new Error('Invalid options.');
        } else n = {};
        i.isDoc = 'html' === i.elmTarget.nodeName.toLowerCase();
        var r = (i.document = i.elmTarget.ownerDocument);
        i.window = r.defaultView;
        var a = (i.elmTargetBody = i.isDoc ? r.body : i.elmTarget);
        if (!r.getElementById(ue)) {
          var l = r.getElementsByTagName('head')[0] || r.documentElement,
            s = l.insertBefore(r.createElement('style'), l.firstChild);
          (s.type = 'text/css'),
            (s.id = ue),
            (s.textContent = Q.a),
            (ge || ve) && Se(s);
        }
        var c,
          u = (i.elmOverlay = r.createElement('div')),
          d = C(u);
        d.add('plainoverlay', pe),
          i.isDoc && d.add(de),
          (i.transition = new $(u, {
            procToOn: function (e) {
              var t = C(u);
              t.toggle(me, !!e), t.add(fe);
            },
            procToOff: function (e) {
              var t = C(u);
              t.toggle(me, !!e), t.remove(fe);
            },
            property: 'opacity',
            duration: ''.concat(200, 'ms'),
          })),
          u.addEventListener(
            'timedTransitionEnd',
            function (e) {
              e.target === u &&
                'opacity' === e.propertyName &&
                (1 === i.state ? Fe(i) : 3 === i.state && je(i));
            },
            !0,
          ),
          (i.isDoc ? i.window : a).addEventListener(
            'scroll',
            function (e) {
              var t = e.target;
              0 !== i.state &&
                !i.blockingDisabled &&
                Ce(
                  i,
                  !i.isDoc ||
                    (t !== i.window &&
                      t !== i.document &&
                      t !== i.elmTargetBody)
                    ? t
                    : i.elmTarget,
                ) &&
                (e.preventDefault(), e.stopImmediatePropagation());
            },
            !0,
          ),
          (i.focusListener = function (e) {
            0 !== i.state &&
              !i.blockingDisabled &&
              Ae(i, e.target) &&
              (e.preventDefault(), e.stopImmediatePropagation());
          }),
          a.addEventListener('focus', i.focusListener, !0),
          (c = function (e) {
            0 !== i.state &&
              !i.blockingDisabled &&
              Re(i) &&
              (e.preventDefault(), e.stopImmediatePropagation());
          }),
          ['keyup', 'mouseup'].forEach(function (e) {
            i.window.addEventListener(e, c, !0);
          }),
          (i.resizing = !1),
          i.window.addEventListener(
            'resize',
            B.add(function () {
              if (!i.resizing) {
                if (((i.resizing = !0), 0 !== i.state)) {
                  if (i.isDoc)
                    i.savedElementsScroll.length &&
                      i.savedElementsScroll[0].isDoc &&
                      (i.disabledDocBars &&
                        (De(i.elmTarget, i.savedStyleTarget, ['overflow']),
                        De(a, i.savedStyleTargetBody, [
                          'marginLeft',
                          'marginRight',
                          'marginTop',
                          'marginBottom',
                          'width',
                          'height',
                        ])),
                      (i.disabledDocBars = Le(i)));
                  else {
                    var e = xe(a, i.window),
                      t = i.targetBodyBBox;
                    (e.left === t.left &&
                      e.top === t.top &&
                      e.width === t.width &&
                      e.height === t.height) ||
                      Ie(i, e);
                  }
                  i.options.onPosition && i.options.onPosition.call(i.ins);
                }
                i.resizing = !1;
              }
            }),
            !0,
          ),
          u.addEventListener(
            'touchmove',
            function (e) {
              0 !== i.state &&
                (e.preventDefault(), e.stopImmediatePropagation());
            },
            !0,
          ),
          ((i.elmOverlayBody = u.appendChild(
            r.createElement('div'),
          )).className = he),
          r.body.appendChild(u),
          n.hasOwnProperty('face') || (n.face = null),
          ze(i, n);
      }
      var t, n, o;
      return (
        (t = e),
        (o = [
          {
            key: 'show',
            value: function (t, n) {
              return new e(t, n).show();
            },
          },
          {
            key: 'STATE_HIDDEN',
            get: function () {
              return 0;
            },
          },
          {
            key: 'STATE_SHOWING',
            get: function () {
              return 1;
            },
          },
          {
            key: 'STATE_SHOWN',
            get: function () {
              return 2;
            },
          },
          {
            key: 'STATE_HIDING',
            get: function () {
              return 3;
            },
          },
        ]),
        (n = [
          {
            key: 'setOptions',
            value: function (e) {
              return Te(e) && ze(Oe[this._id], e), this;
            },
          },
          {
            key: 'show',
            value: function (e, t) {
              return (
                arguments.length < 2 &&
                  'boolean' != typeof e &&
                  ((t = e), (e = !1)),
                this.setOptions(t),
                He(Oe[this._id], e),
                this
              );
            },
          },
          {
            key: 'hide',
            value: function (e) {
              return (
                (function (e, t) {
                  if (
                    !(
                      0 === e.state ||
                      (3 === e.state && !t) ||
                      (3 !== e.state &&
                        e.options.onBeforeHide &&
                        !1 === e.options.onBeforeHide.call(e.ins))
                    )
                  ) {
                    e.filterElements &&
                      (e.filterElements.forEach(function (e) {
                        De(e.element, e.savedStyle);
                      }),
                      (e.filterElements = null));
                    var n = e.document.activeElement;
                    n &&
                      n !== n.ownerDocument.body &&
                      e.elmOverlay.compareDocumentPosition(n) &
                        Node.DOCUMENT_POSITION_CONTAINED_BY &&
                      (n.blur ? n.blur() : n.ownerDocument.body.focus()),
                      e.transition.off(t),
                      (e.state = 3),
                      t && je(e);
                  }
                })(Oe[this._id], e),
                this
              );
            },
          },
          {
            key: 'scrollLeft',
            value: function (e, t) {
              return Ye(Oe[this._id], t, !0, e);
            },
          },
          {
            key: 'scrollTop',
            value: function (e, t) {
              return Ye(Oe[this._id], t, !1, e);
            },
          },
          {
            key: 'position',
            value: function () {
              var e = Oe[this._id];
              return (
                0 !== e.state &&
                  (e.isDoc || Ie(e, xe(e.elmTargetBody, e.window)),
                  e.options.onPosition && e.options.onPosition.call(e.ins)),
                this
              );
            },
          },
          {
            key: 'state',
            get: function () {
              return Oe[this._id].state;
            },
          },
          {
            key: 'style',
            get: function () {
              return Oe[this._id].elmOverlay.style;
            },
          },
          {
            key: 'blockingDisabled',
            get: function () {
              return Oe[this._id].blockingDisabled;
            },
            set: function (e) {
              'boolean' == typeof e && (Oe[this._id].blockingDisabled = e);
            },
          },
          {
            key: 'face',
            get: function () {
              return Oe[this._id].options.face;
            },
            set: function (e) {
              ze(Oe[this._id], { face: e });
            },
          },
          {
            key: 'duration',
            get: function () {
              return Oe[this._id].options.duration;
            },
            set: function (e) {
              ze(Oe[this._id], { duration: e });
            },
          },
          {
            key: 'blur',
            get: function () {
              return Oe[this._id].options.blur;
            },
            set: function (e) {
              ze(Oe[this._id], { blur: e });
            },
          },
          {
            key: 'onShow',
            get: function () {
              return Oe[this._id].options.onShow;
            },
            set: function (e) {
              ze(Oe[this._id], { onShow: e });
            },
          },
          {
            key: 'onHide',
            get: function () {
              return Oe[this._id].options.onHide;
            },
            set: function (e) {
              ze(Oe[this._id], { onHide: e });
            },
          },
          {
            key: 'onBeforeShow',
            get: function () {
              return Oe[this._id].options.onBeforeShow;
            },
            set: function (e) {
              ze(Oe[this._id], { onBeforeShow: e });
            },
          },
          {
            key: 'onBeforeHide',
            get: function () {
              return Oe[this._id].options.onBeforeHide;
            },
            set: function (e) {
              ze(Oe[this._id], { onBeforeHide: e });
            },
          },
          {
            key: 'onPosition',
            get: function () {
              return Oe[this._id].options.onPosition;
            },
            set: function (e) {
              ze(Oe[this._id], { onPosition: e });
            },
          },
        ]) && re(t.prototype, n),
        o && re(t, o),
        e
      );
    })();
    t.default = Ke;
  },
]).default;
window.PlainOverlay = PlainOverlay;
