import { Iodine } from '@kingshott/iodine';
import { isElementHidden } from '@/utils/app';

function form() {
  return {
    iodine: new Iodine(),
    formEl: null,
    init() {
      this.formEl = (this as any).$el.querySelector('form');
      this.addEventListeners();
    },
    addEventListeners() {
      const { formEl, iodine } = this as any;
      if (formEl) {
        (formEl as HTMLFormElement).addEventListener('submit', (e: any) => {
          const formItems = Array.from(
            document.querySelectorAll('[x-ref="formItem"]'),
          ).map((element: any) => ({
            el: element,
            input: element.querySelector(
              '[x-ref="formItemInput"] input, [x-ref="formItemInput"] textarea, [x-ref="formItemInput"] select',
            ),
          }));
          const invalidElements = formItems.filter((formItem: any) => {
            return (
              iodine.is(
                formItem.input.value,
                JSON.parse(formItem.el.dataset.rules),
              ) !== true && !isElementHidden(formItem.el)
            );
          });
          if (invalidElements.length > 0) {
            e.preventDefault();
            (window as any).Scroller.scrollTo(invalidElements[0].el, 15);
            invalidElements.forEach((formItem: any) => {
              if (document.activeElement) {
                (document as any).activeElement.blur();
              }
              formItem.input.blur();
            });
          }
        });
      }
    },
  };
}

(window as any).form = form;
