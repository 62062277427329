function stickyMenuItem() {
  return {
    states: [],
    onMouseEnter() {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      this.states = [].concat(this.states, ['hover']);
    },
    onMouseLeave() {
      this.states = this.states.filter((item) => item !== 'hover');
    },
  };
}

(window as any).stickyMenuItem = stickyMenuItem;
