export const getNavigationOffset = () => {
  return ['.navigation']
    .map((selector) => {
      const el: HTMLElement | null = document.querySelector(selector);
      return el ? el.offsetHeight : 0;
    })
    .reduce((accumulator, currentValue) => accumulator + currentValue);
};

export const getUrlParamByName = (
  name: string,
  url: string = window.location.href,
) => {
  // eslint-disable-next-line no-param-reassign
  name = name.replace(/[\[\]]/g, '\\$&');
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
  const results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

export const updateURLParameter = (
  url: string,
  param: string,
  paramVal: any,
) => {
  let TheAnchor = null;
  let newAdditionalURL = '';
  let tempArray = url.split('?');
  let baseURL = tempArray[0];
  let additionalURL = tempArray[1];
  let temp = '';

  if (additionalURL) {
    const tmpAnchor = additionalURL.split('#');
    const TheParams = tmpAnchor[0];
    TheAnchor = tmpAnchor[1];
    if (TheAnchor) additionalURL = TheParams;

    tempArray = additionalURL.split('&');

    for (let i = 0; i < tempArray.length; i += 1) {
      if (tempArray[i].split('=')[0] !== param) {
        newAdditionalURL += temp + tempArray[i];
        temp = '&';
      }
    }
  } else {
    const tmpAnchor = baseURL.split('#');
    const TheParams = tmpAnchor[0];
    TheAnchor = tmpAnchor[1];

    if (TheParams) baseURL = TheParams;
  }

  if (TheAnchor) paramVal += `#${TheAnchor}`;

  const rowsTxt = `${temp}${param}=${paramVal}`;
  return `${baseURL}?${newAdditionalURL}${rowsTxt}`;
};

export const isElementHidden = (el: HTMLElement): boolean => {
  const style = window.getComputedStyle(el);
  return style.display === 'none';
};
