const screens = {
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xl2: '1400px',
};
const unit = {
  base: '56px',
};

const spacing = {
  default: '40px',
  xs3: '2px',
  xs2: '4px',
  xs: '8px',
  sm: '12px',
  md: '16px',
  lg: '24px',
  xl: '32px',
  xl2: '48px',
  xl3: '64px',
  xl4: '96px',
};

const colors = {
  primary: {
    dark: '#B80511',
    base: '#E30613',
    light: '#F9CDD0',
    lighter: '#FEF2F3',
  },
  secondary: {
    base: '#1D1D1B',
    light: '#1D1D1BE6', // 90% opacity
    lighter: '#1D1D1BB3', // 70% opacity
  },
  tertiary: {
    base: '#F19A3E',
    light: '#FCEBD8',
  },
  quaternary: {
    base: '#96A13A',
    light: '#EAECD8',
  },
  quinary: {
    base: '#2155A6',
    light: '#D3DDED',
  },
  grey: {
    base: '#7E8890',
    light: '#D9DCDE',
    lighter: '#E5E7E9',
    lightest: '#F6F7F7',
  },
  success: {
    base: '#009625',
  },
};

const pristineConfig = {
  unit,
  screens,
  bootstrapBreakpoints: {
    xs: 0,
    ...screens,
  },
  bootstrapMaxWidths: screens,
  components: {
    navigation: {
      height: {
        desktop: '100px',
      },
    },
  },
  gutters: {
    base: '20px',
    ...spacing,
  },
  colors: {
    ...colors,
  },
  spacing: {
    base: '16px',
    ...spacing,
  },
  transitions: {
    fast: 'all 0.1s ease-in',
    base: 'all 0.2s ease-in',
  },
  backgroundColor: {},
  backgroundPosition: {},
  backgroundSize: {},
  borderColor: {
    ...colors,
  },
  borderRadius: {
    base: '4px',
  },
  borderWidth: {},
  boxShadow: {},
  container: {},
  cursor: {},
  fill: {},
  flex: {},
  flexGrow: {},
  flexShrink: {},
  fontFamily: {
    sans: [
      'Roboto',
      'ui-sans-serif',
      'system-ui',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      '"Noto Sans"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
      '"Noto Color Emoji"',
    ],
  },
  fontSize: {
    xs2: '11px',
    xs: '13px',
    sm: '14px',
    base: '16px',
    md: '18px',
    lg: '20px',
    xl: '23px',
    xl2: '26px',
    xl3: '29px',
    xl4: '32px',
    xl5: '36px',
    xl6: '48px',
  },
  fontWeight: {},
  height: {},
  inset: {},
  letterSpacing: {
    sm: '0.02em', // around 0.25px on 13px font
    md: '0.035em',
    lg: '0.075em',
  },
  lineHeight: {
    xs: '1.2',
    sm: '1.5',
    base: '1.6',
  },
  listStyleType: {},
  margin: {},
  maxHeight: {},
  maxWidth: {},
  minHeight: {},
  minWidth: {},
  objectPosition: {},
  opacity: {},
  order: {},
  padding: {},
  stroke: {},
  textColor: {},
  width: {},
  zIndex: {},
};

module.exports = pristineConfig;
