function articles({ selectId, tags, locale }: any) {
  if (!Array.isArray(tags)) {
    tags = tags.split(',');
  }
  return {
    tags,
    locale,
    page: 0,
    loadingMore: false,
    scrolledDown: false,
    async init() {
      this.fetchArticles(this.tags, this.page);
      this.addEventListeners();
    },
    addArticles(articles: string, replaceContent: boolean = false) {
      (this as any).$refs['articles'].innerHTML =
        (replaceContent ? '' : (this as any).$refs['articles'].innerHTML) +
        articles;
    },
    fetchArticles(
      tags: string[],
      page: number,
      replaceContent: boolean = false,
    ) {
      this.loadingMore = true;
      return new Promise(async (resolve, reject) => {
        const articles = await (
          await fetch(
            `/${this.locale}/articles-filter/${page}?tags=${tags.join(
              ',',
            )}&locale=${this.locale}`,
          )
        ).text();
        if (articles) {
          this.addArticles(articles, replaceContent);
          return resolve(true);
        }
        return reject();
      }).finally(() => {
        this.loadingMore = false;
      });
    },
    addEventListeners() {
      window.addEventListener('selectMultipleChange', ({ detail }: any) => {
        if (detail.id === selectId) {
          this.tags = detail.value;
          this.page = 0;
          this.fetchArticles(detail.value, this.page, true);
        }
      });
      window.addEventListener('scroll', (e: Event) => {
        const { scrollTop, clientHeight } = document.documentElement;
        const articlesSectionEl = (this as any).$refs['articles-section'];
        const articlesSectionPosition =
          articlesSectionEl.getBoundingClientRect();
        if (
          scrollTop + clientHeight >
            articlesSectionPosition.top +
              document.documentElement.scrollTop +
              articlesSectionPosition.height &&
          !this.loadingMore &&
          !this.scrolledDown
        ) {
          this.scrolledDown = true;
          this.page += 1;
          this.fetchArticles(this.tags, this.page).catch(() => {
            this.page -= 1;
          });
        }
        if (
          !(
            scrollTop + clientHeight >
            articlesSectionPosition.top +
              document.documentElement.scrollTop +
              articlesSectionPosition.height
          )
        ) {
          this.scrolledDown = false;
        }
      });
    },
  };
}

(window as any).articles = articles;
